body {
  color: #000 !important;
}

p {
  padding: 0px !important;
  margin: 0px !important;
}

.print td {
  border: 0px solid #000 !important;
  padding: 2px 5px !important;
  font-family: 'Arial' !important;
  line-height: 1.2 !important;
}

.print-table-with-border td {
  border: 1px solid #000 !important;
  padding: 2px 5px !important;
}

.page-number {
  position: fixed;
  bottom: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
}

