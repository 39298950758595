html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.table-with-border table {
  border: 1px solid black;
  border-collapse: collapse;
}

.table-with-border td {
  padding: 1mm;
  border: 1px solid black;
  border-collapse: collapse;
  /* vertical-align: baseline !important; */
}

.table-with-border th {
  padding: 1mm;
  border: 1px solid black;
  border-collapse: collapse;
  vertical-align: baseline !important;
}

.table-with-out-border table {
  border: 0px solid black;
  border-collapse: collapse;
}

.table-with-out-border td {
  padding: 1mm;
  border: 2px solid black;
  border-collapse: collapse;
  vertical-align: baseline !important;
}

.table-with-out-border th {
  padding: 1mm;
  border: 2px solid black;
  border-collapse: collapse;
  vertical-align: baseline !important;
}

.table-grid {
  height: 45px;
  border: 0.5px solid rgba(0, 0, 0, 0.6);
  margin-top: -2px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.center-text  {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media print {

  footer {
    position: fixed;
    bottom: 0;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  #pageFooter {
    content: counter(page) ' of ' counter(pages);
  }
}

#content {
  display: table;
}


aside {
  flex: 0 1;
  margin-right: 30px;
}

section {
  flex: 1;
}

.token {
  display: inline-block;
  border-radius: 0.75em;
  background-color: #07c;
  color: #fff;
  padding: 0 8px;
  line-height: 1.5;
  white-space: nowrap;
  font-size: 13px;
  user-select: none;
  margin-left: 1px;
  margin-right: 1px;
  border: none;
}

.token.selected {
  background-color: #2ad;
}

/*
  Required for clicked token to be recognized by Parchment.
  If a child element receives the click, Parchment is unable to determine which blot was clicked.
*/
.token * {
  pointer-events: none;
}

.token-list {
  list-style-type: none;
  padding-left: 0;
}

.token-list .token {
  cursor: pointer;
}

.token-list > li {
  position: relative;
}

.token-list > li + li {
  margin-top: 5px;
}

.token-list ul {
  position: absolute;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #666;
  border-radius: 4px;
  z-index: 9999;
  padding: 0;
  overflow: hidden;
  top: 100%;
  left: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.6);
}

.token-list ul > li + li {
  border-top: 1px solid #666;
}

.token-list ul button {
  border: none;
  background: #fff;
  font-size: 13px;
  cursor: pointer;
}

.token-list ul button:hover,
.token-list ul button:active {
  color: #07c;
}

/* hide div in print mode */
@media print {
  .no-print {
    display: none;
  }
}

.ql-align-center {
  text-align: center;
}