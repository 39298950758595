body {
  color: #000 !important;
}

p {
  padding: 0px !important;
  margin: 0px !important;
}

.print td {
  border: 0px solid #000 !important;
  padding: 2px 5px !important;
}

td {
  vertical-align: top;
}


.print-table-with-border td {
  border: 1px solid #000 !important;
  padding: 2px 5px !important;
}

.page-number {
  position: fixed;
  bottom: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
}


/* @page {
  size: 297mm 210mm;
  margin: 5mm 5mm 5mm 5mm;

  counter-increment: page;

  @bottom-right {
    content: "Page " counter(page);
  }
} */