/* Invoice.css */
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

.font-container {
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
}


.item-header{
    color: rgba(28, 100, 0, 1);
}
.header-container {
    /* background-color: #d2ead5 !important; */
    padding: 10mm;
    padding-bottom: 0;
}

.header-border {
    border-bottom: 1px solid rgba(28, 100, 0, 1);
    padding-bottom: 5mm;
}

.logo {
    mix-blend-mode: multiply;
    height: 30mm;
}

.d-flex-space-between {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.print-format {
    background-color: white;
    border-radius: 8px;
    max-width: 8.3in;
    min-height: 11.69in;
    padding: 0in;
    margin: auto;
}

.header-text {
    font-size: 15px;
}

.bold-title {
    font-size: 15px;
    font-weight: 700;
}

.doc-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
}

.right-text {
    text-align: right !important;
}

.bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

table {
    border-collapse: collapse;
}

.mt-5 {
    margin-top: 5mm;
}

.main-container {
    padding: 10mm;
    font-size: 14px;
}

.company-name {
    text-transform: uppercase;
}

.item-table th {
    text-align: start;
}

.total-table {
    text-align: left;
    width: 95mm;
}

.total-container {
    display: flex;
    margin-top: 2mm;
    justify-content: flex-end;
}
